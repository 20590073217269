import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b2c1074 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _116bb9ec = () => interopDefault(import('../pages/misc/call-error.vue' /* webpackChunkName: "pages/misc/call-error" */))
const _0fb5b88b = () => interopDefault(import('../pages/misc/call-rating.vue' /* webpackChunkName: "pages/misc/call-rating" */))
const _f443adf6 = () => interopDefault(import('../pages/misc/call-success.vue' /* webpackChunkName: "pages/misc/call-success" */))
const _6be19593 = () => interopDefault(import('../pages/misc/inbound-error.vue' /* webpackChunkName: "pages/misc/inbound-error" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4a0fc761 = () => interopDefault(import('../pages/conference/_key.vue' /* webpackChunkName: "pages/conference/_key" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/help",
    component: _5b2c1074,
    name: "help"
  }, {
    path: "/misc/call-error",
    component: _116bb9ec,
    name: "misc-call-error"
  }, {
    path: "/misc/call-rating",
    component: _0fb5b88b,
    name: "misc-call-rating"
  }, {
    path: "/misc/call-success",
    component: _f443adf6,
    name: "misc-call-success"
  }, {
    path: "/misc/inbound-error",
    component: _6be19593,
    name: "misc-inbound-error"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }, {
    path: "/conference/:key?",
    component: _4a0fc761,
    name: "conference-key"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
