export default {
  // check incoming call and current call
  validate({ commit }, payload = {}) {
    const { key } = payload
    const k = key

    delete payload.token
    return this.$axios
      .$post(`/api/v1/conferences/${k}/actions/validate`, payload)
      .then((data) => {
        return data
      })
  },

  get({ commit }, payload = {}) {
    const { key } = payload

    return this.$axios.$get(`/api/v1/conferences/${key}`).then((data) => {
      commit('SET_CONFERENCE', data)
      return data
    })
  },

  end({ commit }, payload) {
    const { callId } = payload

    return this.$axios
      .$patch(`/api/v1/calls/${callId}/actions/end-call`, payload)
      .then((data) => {
        return data
      })
  },

  saveChatLog({ commit }, payload = {}) {
    return this.$axios
      .$post(`/api/v1/chats/actions/cache`, payload)
      .then((data) => {
        return data
      })
  },

  fetchChatLogs({ commit }, payload = {}) {
    const { callId, roomId } = payload

    return this.$axios
      .$get(`/api/v1/chats/${callId}?roomId=${roomId}`)
      .then((data) => {
        commit('SET_CHAT_LOGS', data)
        return data
      })
  },

  saveRating({ commit }, payload) {
    const { callId } = payload

    return this.$axios
      .$patch(`/api/v1/calls/${callId}/actions/save-rating`, payload)
      .then((data) => {
        return data
      })
  }
}
