export default {
  uploadImage({ commit }, payload = {}) {
    // get pre-signed url
    const { id, blob, key } = payload
    const category = 'snapshot'
    const qs = encodeURI(
      `name=${`${id}.png`}&type=${blob.type}&category=${category}`
    )

    const basicAuth = window.btoa(`guest:${key}`)

    // upload to s3
    const d = new Date()
    const fn = `${id}_${d.getTime()}.png`
    let url = ''

    return this.$axios
      .$get(`/api/v1/ext/s3/urls?${qs}`, {
        headers: {
          Authorization: `Basic ${basicAuth}`
        }
      })
      .then(({ signedUrl, publicUrl }) => {
        url = publicUrl
        return this.$axios.$put(signedUrl, blob, {
          headers: {
            'Content-Type': blob.type,
            'Content-Disposition': `attachment;filename="${fn}"`
          }
        })
      })
      .then(() => {
        return url
      })
  },

  downloadImage({ commit }, payload = {}) {
    const { key, url } = payload
    const basicAuth = window.btoa(`guest:${key}`)

    return this.$axios.$get(`/ext${url}`, {
      headers: {
        Authorization: `Bearer ${basicAuth}`
      }
    })
  }
}
