import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagVue from '@bugsnag/plugin-vue'

export default (ctx, inject) => {
  const { store, env } = ctx

  const conference = store.getters['conference/getConference']

  const bugsnagClient = Bugsnag.start({
    apiKey: env.BUGSNAG_KEY,
    plugins: [new BugsnagVue()],
    enabledReleaseStages: ['production', 'staging', 'development'],
    user: {
      id: 'guest'
    },

    metadata: {
      ...conference
    }
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)

  window.bugsnag = bugsnagClient
  inject('bugsnag', bugsnagClient)
}
