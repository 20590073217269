export function playSound(url, id) {
  const audio = document.createElement('audio')
  audio.id = id
  audio.style.display = 'none'
  audio.src = url
  audio.autoplay = true
  audio.onended = function() {
    // Remove when played.
    audio.remove()
  }

  audio.onerror = function() {}
  document.body.appendChild(audio)
}

export function stopSound(id) {
  const audio = document.getElementById(id)

  if (audio) {
    audio.pause()
    audio.remove()
  }
}

export function dataURIToBlob(dataURI = '') {
  if (dataURI) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].includes('base64')
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }

  return undefined
}

export function handleError(ctx, error, msg, options = {}) {
  let errorMsg = msg
  let code = ''

  if (error.response) {
    if (error.response.data) {
      const { message } = error.response.data

      if (message) {
        errorMsg = msg
      }
    }

    if (error.response.status) {
      code = parseInt(error.response.status, 10)
    }
  }

  if (
    [500, 400, 301, 304].includes(code) ||
    (errorMsg &&
      errorMsg.match(
        /undefined|unexpected|uncaught|null|not a function|size exceeded|bad|illegal|unterminated|unreachable|database|requestError/i
      ))
  ) {
    errorMsg = 'Sorry, an unexpected error occured.'
  }

  if (errorMsg && code !== 401) {
    const timeout = options.timeout || 1.5
    ctx.$message.error(errorMsg, timeout)
  }

  // eslint-disable-next-line no-console
  console.error(error)

  ctx.$bugsnag.notify(error, (ev) => {
    ev.severity = options.severity || 'error'
  })
}

export function getMediaDevices() {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    return Promise.reject(
      new Error('Enumeration of device(s) is not supported.')
    )
  }

  // List cameras and microphones.
  return navigator.mediaDevices.enumerateDevices().then(function(devices) {
    return devices
  })
}

export function getMediaPermission(videoConstraints) {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {}
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function(constraints) {
      // First get ahold of the legacy getUserMedia, if present
      const getUserMedia =
        navigator.webkitGetUserMedia || navigator.mozGetUserMedia

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(new Error('Browser not supported.'))
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise((resolve, reject) => {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }

  return navigator.mediaDevices.getUserMedia({
    audio: true,
    video: videoConstraints || true
  })
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function getSupportedBrowsers(ms) {
  return [
    { browser: 'chrome', version: 72, isChromium: true, isSupported: true },
    { browser: 'edge', version: 79, isChromium: true, isSupported: true },
    { browser: 'opera', version: 91, isChromium: true, isSupported: true },
    { browser: 'brave', version: 1.49, isChromium: true, isSupported: true },
    // { browser: 'firefox', version: 68, isChromium: false, isSupported: true },
    { browser: 'safari', version: 14, isChromium: false, isSupported: true },
    { browser: 'samsung', version: 3, isChromium: false, isSupported: true }
  ]
}
