import {
  isMobile,
  isAndroid,
  browserVersion,
  browserName
} from 'mobile-device-detect'

import * as isWebview from 'is-ua-webview'

import { getSupportedBrowsers } from '@/common/utils'

const redirectToChrome = () => {
  const fallbackUrl = 'https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.android.chrome%26hl%3Den'; //eslint-disable-line

  const intent =
    '#Intent;scheme=https;package=com.android.chrome;S.browser_fallback_url=' +
    fallbackUrl +
    ';end;' //eslint-disable-line

  const url =
    'intent://' + window.location.host + window.location.pathname + intent

  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()

  return false
}

export default function({ redirect }) {
  const supportedBrowsers = getSupportedBrowsers()

  const supportedBrowser = supportedBrowsers.find((v) => {
    const pattern = v.browser.toLowerCase()
    const re = new RegExp(pattern, 'gi')
    return browserName.match(re)
  })

  const isSupportedBrowserVersion =
    supportedBrowser && parseInt(browserVersion, 10) >= supportedBrowser.version

  if (!supportedBrowser || isWebview(navigator.userAgent)) {
    if (isMobile) {
      if (isAndroid) {
        try {
          redirectToChrome()
        } catch (e) {
          return redirect('/misc/call-error?status=B')
        }
      } else {
        redirect('/misc/call-error?status=B')
      }
    } else {
      redirect('/misc/call-error?status=B')
    }
  }

  if (!isSupportedBrowserVersion) {
    return redirect('/misc/call-error?status=BU')
  }
}
