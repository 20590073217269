export default {
  SET_CONFERENCE(state, payload = {}) {
    state.conference = payload
  },
  SET_CHAT_LOGS(state, log = []) {
    let chatLogs = state.chatLogs.concat(log)

    if (chatLogs.length) {
      chatLogs = chatLogs.sort((a, b) => {
        return a.timestamp > b.timestamp ? 1 : 1
      })
    }

    state.chatLogs = chatLogs
  }
}
