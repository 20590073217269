export default {
  create({ commit }, payload = {}) {
    return this.$axios.$post(`/api/v1/inbound-calls`, payload).then((data) => {
      return data
    })
  },

  check({ commit }, payload = {}) {
    const { key } = payload

    return this.$axios
      .$get(`/api/v1/inbound-calls/${key}/actions/check`)
      .then((data) => {
        return data
      })
  },

  cancel({ commit }, payload = {}) {
    const { key } = payload

    return this.$axios
      .$post(`/api/v1/inbound-calls/${key}/actions/cancel`)
      .then((data) => {
        return data
      })
  }
}
